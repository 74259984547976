<template>

  <div class="card card-custom gutter-b card-stretch"
    :class="{ '': group.enabled }" >
    <div class="card-body">
      <div class="d-flex mb-7 aling-items-center">
        <!-- <div class="flex-shrink-0 mr-4">
          <div class="symbol symbol-circle symbol-lg-75">
            <img
              :src="
                group.logo
                  ? group.logo.uri
                  : '/assets/media/bipart/logo-default.svg'
              "
              class="rounded w-100"
              alt=""
            />
          </div>
        </div> -->
        <div class="d-flex flex-column" v-if="group.registrationAllowed != false">
          <router-link
          :to="{ name: 'groupDetail', params: { id: group.id } }"
          v-slot="{ href, navigate }">
          <a :href="href" class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
          :class="{ '': group.enabled }"  @click="navigate">{{ group.name }}</a>
          </router-link>
           <!-- <span class="text-muted font-weight-bold">{{ group.users.length }} utenti</span> -->
        </div>
        <div class="d-flex flex-column" v-else>
          <a 
            class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
            href="#"
            @click.prevent="alertInfo"
            id="pop-info">
            {{ group.name }}
          </a>
           <!-- <span class="text-muted font-weight-bold">{{ group.users.length }} utenti</span> -->
        </div>

      </div>
       <!-- Elenco users
      <p class="mb-7">{{ group.description }}</p>
      <div class="d-flex flex-row mb-7">
        <div v-if="group.users.length > 0" class="d-flex">
          <div class="d-flex align-items-center">
            <div
              v-for="user in iconsUsers"
              :key="user.id"
              class="d-flex align-items-center"
            >
              <div class="symbol symbol-circle mr-3">
                <img
                  :src="
                    user.avatar
                      ? user.avatar.uri
                      : '/assets/media/users/default.jpg'
                  "
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="d-flex flex-row align-items-end">

        <div class="d-flex mr-2">
          <span class="label label-inline label-xl font-weight-boldest label-rounded py-5" 
            v-bind:class="{ 'label-light-primary': group.registrationAllowed, 'label-light-danger' : !group.registrationAllowed }"
          >
          <i class="fas fa-lock-open text-primary" v-if="group.registrationAllowed != false"></i>
          <i class="fas fa-lock text-danger" v-else></i>
          </span>
        </div>
        <div  v-if="group.registrationAllowed != false">
          <router-link
            :to="{ name: 'groupDetail', params: { id: group.id } }"
            v-slot="{ href, navigate }">
            <a :href="href" class="btn btn-sm btn-primary font-weight-bolder text-uppercase text-white"
              :class="{ 'btn-primary': group.enabled }"
              @click="navigate">
              {{$t("PARTIALSPROCESSI.VISUALIZZA")}}
            </a>
          </router-link>
        </div>
        <div  v-else>
          <a 
            href="#"
            class="btn btn-sm btn-danger font-weight-bolder text-uppercase text-white"
            @click.prevent="alertInfo"
            id="pop-info">
            {{$t("PARTIALSPROCESSI.RICHIEDIACCESSO")}}
          </a>
        </div>
      </div>
    </div>
  </div>
  

</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "GroupCard",
  props: ["group"],
  data() {
    return {
     iconsUsers: this.group.users.reverse().slice(0, 3)
    };
  },
  filters: {
    fvisibility(value) {
      return value ? "Pubblico" : "Privato";
    }
  },
  methods: {
    alertInfo(){
      Swal.fire({
        title: this.$t("PARTIALSPROCESSI.ALERT1TITLE"),
        html: this.$t("PARTIALSPROCESSI.ALERT1"),
        icon: "danger",
        confirmButtonClass: "btn btn-danger"
      });
    }
  },
};
</script>

<style scoped></style>
