<template>
  <div class="row h-100 justify-content-between">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>
    <div class="col-lg-9 col-xl-9 justify-content-start">
      <div class="d-flex flex-column px-6 mt-5">
        <!--::Process Info -->
        <div class="card mb-3 card-stretch shadow-sm">
          <div class="card-header border-0 pb-4">
            <div class="row align-items-center">
              <div class="col-12">
                <h1
                  class="text-dark text-hover-primary font-weight-bolder mb-1"
                >
                  {{$t("PROCESSLISTGROUPS.COMMUNITY")}}
                </h1>
                <p class="w-75">
                  {{$t("PROCESSLISTGROUPS.TUTTI")}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--::Tool mobile-->
        <div class="row my-2">
          <ProcessMenuMobile :process_id="id"></ProcessMenuMobile>
        </div>
      </div>

      <div class="d-flex flex-column px-6 mt-5">
        <!--::Search-->
        <div class="row">
          <div class="col-xl-12">
            <form method="get" class="quick-search-form">
              <div class="input-group bg-tranparent border-0">
                <div class="input-group-prepend bg-transparent">
                  <button
                    class="input-group-text line-height-0 py-0 bg-transparent border-0"
                    v-on:click="getSearchData($event)"
                  >
                    <img
                      src="/assets/media/bipart/ico-search.png"
                      alt=""
                      class="w-100"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control py-10 h4 bg-transparent border-0 text-dark-50"
                  placeholder="Search..."
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
                  >
                    {{ this.groups ? this.groups.length : 0 }} {{$t("PROCESSLISTGROUPS.GRUPPI")}}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!--::Elenco gruppi-->
        <div class="row mt-4">
          <div
            id="my-cards"
            class="col-xl-4"
            v-for="item in groups"
            :key="item.id"
          >
            <GroupCard :group="item"></GroupCard>
          </div>
        </div>

        <!--:: Search
        <div class="row gutter-b">
          <div class="col-xl-12">
            <form method="get" class="quick-search-form">
              <div class="input-group bg-tranparent border-0">
                <div class="input-group-prepend bg-transparent">
                  <span
                    class="input-group-text line-height-0 py-0 bg-transparent border-0"
                  >
                    <img
                      src="/assets/media/bipart/ico-search.png"
                      alt=""
                      class="w-100"
                    />
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control py-10 h4 bg-transparent border-0 text-dark-50"
                  placeholder="Search..."
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
                  >
                    {{ process.users ? process.users.length : 0 }} iscritti
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>-->

        <!--::Elenco utenti -->

        <!-- <div class="row gutter-b">
          <div
            id="my-users"
            class="col-6 col-md-6 col-lg-4 col-xl-3 mb-3"
            v-for="(item, index) in process.users"
            :key="item.id"
          >
            <UserCard :user="item" :index="index"></UserCard>
          </div>
        </div>-->
      </div>
    </div>
    <div
      class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between  align-items-center"
    >
      <div class="d-flex flex-column mt-5 mr-5">
        <ProcessMenu :process_id="process_id"></ProcessMenu>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Phaseline from "@/view/layout/common/Phaseline";
import ProcessMenu from "@/view/layout/common/ProcessMenu";
import ProcessMenuMobile from "@/view/layout/common/ProcessMenuMobile";

import GroupCard from "@/view/pages/process/partials/GroupCard";
//import UserCard from "@/view/pages/process/partials/UserCard";
import { processMixin } from "@/mixins/process";
import ApiService from "@/core/services/api.service";

export default {
  name: "ProcessListGroups",
  mixins: [processMixin],
  components: {
    GroupCard,
    //UserCard,
    Phaseline,
    ProcessMenu,
    ProcessMenuMobile
  },
  data() {
    return {
      process: [],
      groups: [],
      process_id: this.$route.params.process_id,
      percentage: 0,
      keywords: ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
      { title: this.$t("BCPROCESSI.PROCESSO"), route: "/process-detail/" + this.process_id },
      { title: this.$t("BCPROCESSI.COMMUNITY") }
    ]);
  },
  created() {
    this.process =this.getProcess(this.process_id);
    this.$isLoading(true)
    // this.groups = this.process.groups;
    return new Promise((resolve, reject) => {
      var endpoint = this.generateUrl("groups", true);
      //generateUrl is done
      ApiService.get(this.$apiResource, endpoint + "?processId.equals=" + this.process_id)
          .then(res => {
            console.log("Gruppi del processo: ", res.data);
            this.groups = res.data;
            resolve(res.data);
            this.$isLoading(false);
          })
          .catch(() => {
            reject(0);
            this.$isLoading(false);
          });
    });

    // this.getProcess(this.process_id, "it").then(res => {
    //   this.process = res.data;
    //   this.percentage = this.getPercentage(this.process);
    //   this.groups = res.data.groups;
    // });
  },
  methods: {
    getSearchData(event) {
      event.preventDefault();
      this.getProcess(this.process_id);
    }
  }
};
</script>

<style scoped></style>
